<!--
 * @Description: 高位视频详情弹窗组件
-->
<template>
  <div class="main">
    <el-row class="form"
            :style="colorJson[theme].color">
      <el-row class="firstLine">
        <el-col :span="8"> 设备编号 : {{ deviceForm.deviceId }} </el-col>
        <el-col :span="8"> 设备名称 : {{ deviceForm.deviceName }} </el-col>
        <el-col :span="8"> 运维单位 : {{ deviceForm.vendor }} </el-col>
      </el-row>
      <el-row class="secondLine">
        <el-col :span="8"> 联系人 : {{ deviceForm.contact }} </el-col>
        <el-col :span="8">
          联系电话 : {{ deviceForm.contactPhoneNumber }}
        </el-col>
        <el-col :span="8"> 设备状态 : </el-col>
      </el-row>
      <el-row class="thirdLine">
        <el-col :span="8"> 所属场所 : {{ deviceForm.address }} </el-col>
        <el-col :span="8"> 设备类型 : {{ deviceForm.deviceTypeName }} </el-col>
      </el-row>
      <el-row class="firthLine">
        <el-col :span="22"> 备注信息 : {{ deviceForm.remark }} </el-col>
        <el-col :span="2">
          <el-button type="info"
                     class="workListBtn"
                     @click="maintainListClick(data)">下发工单</el-button>
        </el-col>
      </el-row>
      <el-row class="videoBody"
              v-if="['monitor', 'environment', 'bayonet'].includes(deviceForm.type)">
        <el-row style="width: 100%; height: 400px">
          <div id="flv-app">
            <video controls
                   muted
                   id="videoElementOne"></video>
          </div>
        </el-row>
      </el-row>
    </el-row>
    <!-- 下发工单详情 -->
    <el-dialog :title="dialogTitle"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               append-to-body>
      <el-form label-width="150px"
               :model="popForm"
               ref="popForm"
               :rules="rules"
               :inline="true"
               size="small"
               class="dialogBody">
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="设备类型"
                          prop="deviceTypeName"
                          style="margin-right: 20px">
              <el-input v-model="popForm.deviceTypeName"
                        readonly
                        class="dt-form-width"
                        placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="维护设备"
                          prop="deviceId">
              <el-input v-model="popForm.deviceId"
                        readonly
                        class="dt-form-width"
                        placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="问题类型"
                          prop="deviceFaultType">
              <el-select v-model="popForm.deviceFaultType"
                         class="dt-form-width"
                         placeholder="请选择"
                         style="width: 93%">
                <el-option v-for="(item, index) in deviceFaultTypeList"
                           :key="index"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="问题描述"
                          prop="deviceFaultDesc">
              <el-input v-model="popForm.deviceFaultDesc"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="21">
            <el-form-item label="工单备注"
                          prop="ticketRemark">
              <el-input v-model="popForm.ticketRemark"
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="dt-button-box"
                type="flex"
                justify="end">
          <el-button type="info"
                     @click="confirmClick('popForm')">确认下发</el-button>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import flvjs from "flv.js";
import { mapState } from 'vuex'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    data: {
      type: Object,
    },
  },
  data () {
    //这里存放数据
    return {
      colorJson: {
        dark: {
          color: 'color:white',
        },
        light: {
          color: 'color:black',
        }
      },
      deviceForm: {},// 设备表单
      deviceFaultTypeList: [],    // 问题类型下拉框
      // 问题类型规则校验
      rules: {
        deviceTypeName: [{ required: true, message: "", trigger: "blur" }],
        deviceId: [{ required: true, message: "", trigger: "blur" }],
        deviceFaultType: [
          {
            required: true,
            message: "请选择问题类型",
            trigger: "change",
          },
        ],
        deviceFaultDesc: [
          { required: true, message: "请输入问题描述", trigger: "blur" },
          { min: 1, max: 20, message: "请输入问题描述", trigger: "blur" },
        ],
        ticketRemark: [
          { required: true, message: "请输入工单备注", trigger: "blur" },
        ],
      },

      //详情弹窗
      popForm: {
        deviceTypeName: "",
        deviceId: "",
        deviceFaultType: "",
        deviceFaultDesc: "",
        ticketRemark: "",
      },
      dialogVisible: false, //弹窗是否显示
      dialogTitle: "", // 弹出框标题

      screenRadio: "", //屏幕数量选择
      flvPlayer: null,
      urlHttpFlv: null,
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
    })
  },
  //监控data中的数据变化
  watch: {
    data () {
      this.deviceForm = this.data;
      this.popForm = this.data;
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.deviceForm = this.data;
    this.popForm = this.data;
    if (["monitor", "environment", "bayonet"].includes(this.data.type)) {
      this.getDeviceFaultType();
      this.getQueryDict();
    }
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    //获取停车场名称
    getQueryDict () {
      var params = {
        columnName: ["park_space_id", "park_id"],
        tableName: "tb_park_space",
        whereStr: [
          {
            colName: "park_space_detecter_id",
            value: this.data.deviceId,
          },
        ],
      };
      this.$queryDict.queryDictWithAuth(params).then((response) => {
        let videoList = [];
        if (response.resultEntity[0]) {
          let item = response.resultEntity[0];
          this.handleClickPlay({
            parkId: item.name,
            parkSpaceId: item.code,
          });
        }
        this.videoList = videoList;
        this.selectVideo = videoList[0] ? videoList[0].value : "";
      })
    },
    //获取设备详情表单
    getDeviceDetailList (id, data) {
      this.id = id;
      this.data = data;
    },

    // 获取问题类型
    getDeviceFaultType () {
      let info = {
        columnName: ["value", "name"],
        tableName: "tb_dict",
        whereStr: [
          {
            colName: "parent_id",
            value: "0C3163A66F344DB1A64DE99A717EE84A ",
          },
        ]
      };
      this.$queryDict.queryDict(info).then((res) => {
        this.deviceFaultTypeList = res.resultEntity;
      })
    },

    //获取维护工单
    getDeviceForm () {
      let info = {
        deviceTypeName: this.popForm.deviceTypeName,
        deviceId: this.popForm.deviceId,
        deviceFaultTypeCode: this.popForm.deviceFaultType,
        deviceFaultDesc: this.popForm.deviceFaultDesc,
        ticketRemark: this.popForm.ticketRemark,
      };
      this.$onlineMonitor.createWorkTicketByManager(info).then((res) => {
        if (res.resultCode === "2000") {
          this.$message({ message: "下发成功", type: "success", });
        }
      });
    },
    // 下发工单
    maintainListClick (data) {
      this.dialogVisible = true;
      this.popForm = data;
      this.disabled = true;
      this.dialogTitle = "维护工单";
    },
    // 确认下发
    confirmClick (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dialogVisible = false;
          this.getDeviceForm();
          this.$message({ message: "下发成功", type: "success", });
          this.$emit("cbShow", false); //回调
        }
      });
    },
    //切换屏幕数量 oneScreen fourScreen nineScreen
    handleChangeScreen () {
      console.log(this.screenRadio, "screenRadio");
    },
    // 实时播放点击事件
    handleClickPlay (params) {
      this.$realTimeVideoMonitor.getVideoUrl(params).then((res) => {
        let url = JSON.parse(res.resultEntity).streamingUrl;
        this.flvUrlOne = url;
        if (flvjs.isSupported()) {
          var videoElementOne = document.getElementById("videoElementOne");
          var flvPlayer = flvjs.createPlayer({
            type: "flv",
            url: this.flvUrlOne,
          });
          flvPlayer.attachMediaElement(videoElementOne);
          flvPlayer.load();
          flvPlayer.play();
        }
      });
    },
  },
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .form {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
    color: white;
    font-size: 16px;
    .firstLine {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;
      margin-bottom: 5px;
    }
    .secondLine {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 10px;
      margin-bottom: 5px;
    }
    .thirdLine {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      margin-bottom: 5px;
    }
    .firthLine {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      margin-bottom: 5px;
      .workListBtn {
        float: right;
      }
    }
  }
  .videoBody {
    width: 100%;
    height: 400px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 5px solid #373157;

    #videoElementOne {
      width: 100%;
      height: 400px;
    }
    .left-content-bottom {
      width: 100%;
      height: 3%;
      padding-top: 1%;
      display: flex;
      justify-content: space-between;
    }
  }

  .video {
    object-fill: fill;
  }
  .dialogBody {
    margin-top: 20px;
  }
  .dt-button-box {
    display: flex;
    justify-content: flex-end;
  }
}
</style>