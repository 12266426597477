<!--
 * @Description: 监控信息窗口组件
 * @Author: yanxiao
 * @Github: 
 * @Date: 2021-05-28 09:10:30
 * @LastEditors: zhoucheng
-->
<template>
  <div class="main"
       :style="colorJson[theme].color">
    <div class="close"
         @click="close">x</div>
    <div class="left">
      <img :src="img[status]" />
      <div class="ico"
           :style="'background:'+color[status]">{{statusText[status]}}</div>
    </div>
    <div class="right">
      <div>{{data.deviceName}}</div>
      <div>编号：{{data.deviceId}}</div>
      <div class="details"
           @click="detailsClick">详情>></div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { mapState } from 'vuex'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    id: {
      type: String,
      default: 'monitor'
    },
    // 摄像头状态 1.在线; 2.离线; 3.故障
    status: {
      type: Number,
      default: 1
    },
    // 摄像头编号
    data: {
      type: Object,
      default: () => {
        return {
          deviceName: '正在获取...',
          deviceId: ''
        }
      }
    }
  },
  data () {
    //这里存放数据
    return {
      colorJson: {
        dark: {
          color: 'background: #01325ff5;color:white',
        },
        light: {
          color: 'background: #ffffff;color:black',
        }
      },
      img: {},
      imgJson: {
        'monitor': {
          1: require('@/assets/onlineMonitor/monitorOnlinePoint.png'),
          2: require('@/assets/onlineMonitor/monitorOffline.png'),
          3: require('@/assets/onlineMonitor/monitorfault.png'),
        },
        'environment': {
          1: require('@/assets/onlineMonitor/monitorOnlinePoint.png'),
          2: require('@/assets/onlineMonitor/monitorOffline.png'),
          3: require('@/assets/onlineMonitor/monitorfault.png'),
        },
        'bayonet': {
          1: require('@/assets/onlineMonitor/monitorOnlinePoint.png'),
          2: require('@/assets/onlineMonitor/monitorOffline.png'),
          3: require('@/assets/onlineMonitor/monitorfault.png'),
        },
        'landLock': {
          1: require('@/assets/onlineMonitor/landLockOnline.png'),
          2: require('@/assets/onlineMonitor/landLockOffline.png'),
          3: require('@/assets/onlineMonitor/landLockFault.png'),
        },
        'barrierGate': {
          1: require('@/assets/onlineMonitor/barrierGateOnline.png'),
          2: require('@/assets/onlineMonitor/barrierGateOffline.png'),
          3: require('@/assets/onlineMonitor/barrierGateFault.png'),
        },
        'screen': {
          1: require('@/assets/onlineMonitor/guideScreenOnline.png'),
          2: require('@/assets/onlineMonitor/guideScreenOffline.png'),
          3: require('@/assets/onlineMonitor/guideScreenFault.png'),
        },
      },

      statusText: {
        1: '在线',
        2: '离线',
        3: '故障',
      },
      color: {
        1: '#10BA6C',
        2: '#bda939',
        3: '#bd3939',
      }
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
    })
  },
  //监控data中的数据变化
  watch: {
    data (newValue) {
      this.img = this.imgJson[newValue.type]
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () {
  }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    detailsClick () {
      this.$emit('detailsClick', this.id, this.data)
    },
    close () {
      this.$emit("close")
    },
    queryParkSpace () {
      let info = {
        "columnName": [
          "park_id",
          "park_space_id"
        ],
        "tableName": "tb_park_space",
        "whereStr": [
          {
            "colName": "park_space_detecter_id",
            "value": "2"
          }
        ]
      }
      this.$queryDict.queryDict(info).then(res => {
        console.log(res);
      })
    },
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.main {
  height: 90px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  border-radius: 10px;
  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    .ico {
      width: 60px;
      margin-top: 5px;
      padding: 2px;
      border-radius: 10px;
      font-size: 10px;
      text-align: center;
      line-height: 15px;
    }
  }
  .right {
    width: 250px;
    margin-left: 10px;
    font-size: 14px;
    line-height: 25px;
    .details {
      font-size: 10px;
      color: #249cdd;
      cursor: pointer;
    }
  }
  .close {
    position: absolute;
    top: 15px;
    right: 10px;
    width: 20px;
    height: 15px;
    text-align: center;
    line-height: 10px;
    cursor: pointer;
  }
}
</style>