<!--
 * @Description: 停车场信息窗口组件
 * @Author: yanxiao
 * @Github: 
 * @Date: 2021-05-28 09:10:30
 * @LastEditors: zhoucheng
-->
<template>
  <div :class="theme === 'dark' ? 'main' :'main1'">
    <div class="close"
         :style="theme==='dark' ? 'color:white' :'color:black'"
         @click="close">x</div>
    <div class="top">
      <img :src="img" />
      <div :style="theme==='dark' ? 'color:white' :'color:black'">{{data.parkName || "正在获取信息..."}}</div>
    </div>
    <div class="bottom">
      <div class="bottom-item">
        <div :style="theme==='dark' ? 'color:white' :'color:black'">总车位</div>
        <div class="inAll">{{data.inAll}}</div>
      </div>
      <div class="bottom-item">
        <div :style="theme==='dark' ? 'color:white' :'color:black'">剩余车位</div>
        <div class="minutes">{{data.minutes}}</div>
      </div>
      <div class="details"
           @click="detailsClick">详情 >></div>
    </div>
  </div>

</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { mapState } from 'vuex'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    id: {
      type: String,
      default: 'parking'
    },
    // 摄像头编号
    data: {
      type: Object,
      default: () => {
        return {
          parkName: '正在获取...',
          parkId: ''
        }
      }
    }
  },
  data () {
    //这里存放数据
    return {
      img: require('@/assets/dataBoards/park-p.png'),
      colorJson: {
        dark: {
          fontColor: 'color:white',
        },
        light: {
          fontColor: 'color:black',
        }
      },
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
    })
  },
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () {
  }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    detailsClick () {
      this.$emit('detailsClick', this.id, this.data)
    },
    close () {
      this.$emit("close")
    }
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.main {
  height: 90px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  border-radius: 10px;
  background: #01325ff5 !important;
  .top {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-right: 10px;
    img {
      margin: 0 10px;
    }
  }
  .bottom {
    display: flex;
    flex-direction: row;
    // align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-right: 10px;
    font-size: 14px;
    line-height: 25px;
    .bottom-item {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      color: #ffffff;
      line-height: 18px;
      font-weight: Regular;
      .inAll {
        line-height: 25px;
        font-weight: Bold;
        font-size: 22px;
        color: #12d0ff;
      }
      .minutes {
        line-height: 25px;
        font-weight: Bold;
        font-size: 22px;
        color: #1ac059;
      }
    }
    .details {
      line-height: 18px;
      font-size: 14px;
      font-weight: Regular;
      color: #12afff;
      cursor: pointer;
    }
  }
  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    width: 20px;
    height: 15px;
    text-align: center;
    line-height: 10px;
    cursor: pointer;
  }
}
.main1 {
  height: 90px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  border-radius: 10px;
  background: #ffffff !important;
  .top {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-right: 10px;
    img {
      margin: 0 10px;
    }
  }
  .bottom {
    display: flex;
    flex-direction: row;
    // align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-right: 10px;
    font-size: 14px;
    line-height: 25px;
    .bottom-item {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      color: #ffffff;
      line-height: 18px;
      font-weight: Regular;
      .inAll {
        line-height: 25px;
        font-weight: Bold;
        font-size: 22px;
        color: #12d0ff;
      }
      .minutes {
        line-height: 25px;
        font-weight: Bold;
        font-size: 22px;
        color: #1ac059;
      }
    }
    .details {
      line-height: 18px;
      font-size: 14px;
      font-weight: Regular;
      color: #12afff;
      cursor: pointer;
    }
  }
  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    width: 20px;
    height: 15px;
    text-align: center;
    line-height: 10px;
    cursor: pointer;
  }
}
</style>