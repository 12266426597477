<!--
 * @Description: 地锁信息窗口组件
 * @Author: yanxiao
 * @Github: 
 * @Date: 2021-05-28 09:10:30
 * @LastEditors: yanxiao
-->
<template>
  <div class="main">
    <div class="close"
         @click="close">x</div>
    <div class="left">
      <img :src="img[status]" />
      <div class="ico"
           :style="'background:'+color[status]">{{statusText[status]}}</div>
    </div>
    <div class="right">
      <div>{{data.groundLockName}}</div>
      <div>编号：{{data.groundLockId}}</div>
      <div class="details"
           @click="detailsClick">详情 >></div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    id: {
      type: String,
      default: 'landLock'
    },
    // 摄像头状态 1.在线; 2.离线; 3.故障
    status: {
      type: Number,
      default: 1
    },
    // 摄像头编号
    data: {
      type: Object,
      default: () => {
        return {
          groundLockName: '地锁',
          groundLockId: 'JK_01'
        }
      }
    }
  },
  data () {
    //这里存放数据
    return {
      img: {
        1: require('@/assets/dataBoards/landLock.png'),
        2: require('@/assets/dataBoards/landLock.png'),
      },
      statusText: {
        1: '正常',
        2: '异常',
      },
      color: {
        1: '#10BA6C',
        2: '#bd3939',
      }
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () {
  }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    detailsClick () {
      this.$emit('detailsClick', this.id, this.data)
    },
    close () {
      this.$emit("close")
    }
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.main {
  height: 90px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  border-radius: 10px;
  border: 1px solid #2b678a;
  background: #01325ff5;
  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    margin-right: 10px;
    .ico {
      width: 60px;
      margin-top: 5px;
      padding: 2px;
      border-radius: 10px;
      font-size: 10px;
      text-align: center;
      line-height: 15px;
    }
  }
  .right {
    margin-right: 10px;
    font-size: 14px;
    line-height: 25px;
    .details {
      font-size: 10px;
      color: #249cdd;
      cursor: pointer;
    }
  }
  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    width: 20px;
    height: 15px;
    text-align: center;
    line-height: 10px;
    cursor: pointer;
  }
}
</style>