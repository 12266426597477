<!--
 * @Description: 人员信息窗口
 * @Author: yanxiao
 * @Github: 
 * @Date: 2021-05-28 14:42:28
 * @LastEditors: zhoucheng
-->
<template>
  <div class="main">
    <div class="close"
         @click="close">x</div>
    <div class="row1">
      <el-image style="margin: 0 10px"
                :src="img"></el-image>
      <div>
        <div>巡检员编号：{{data.userCode}}</div>
        <div>最新上报时间:{{data.reportTime}}</div>
      </div>
    </div>
    <div class="details"
         @click='butClick'>详情>></div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    id: {
      type: String,
      default: 'person'
    },
    // 巡检员信息
    data: {
      type: Object,
    }
  },
  data () {
    //这里存放数据
    return {
      img: require('@/assets/dataBoards/person.png'),
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    butClick () {
      this.$emit('butClick', this.data.userCode)
    },
    close () {
      this.$emit("close")
    }
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.main {
  width: 300px;
  height: 80px;
  border-radius: 10px;
  border: 1px solid #2b678a;
  background: #01325ff5;
  overflow: hidden;
  .close {
    position: absolute;
    top: 5px;
    right: 10px;
    width: 20px;
    height: 15px;
    text-align: center;
    line-height: 10px;
    cursor: pointer;
  }
  .row1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 50px;
    font-size: 12px;
    line-height: 20px;
    margin-top: 5px;
    .img {
      margin: 0 10px;
    }
  }
  .details {
    line-height: 18px;
    font-size: 12px;
    font-weight: Regular;
    color: #12afff;
    cursor: pointer;
    margin-left: 80%;
  }
}
</style>