<!--
 * @Description: 路内运营管理系统 数据看板 资源全景 dataBoards
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="body">
    <!-- 地图 -->
    <div class="map-container">
      <div id="map"></div>
    </div>
    <!-- 指南针 -->
    <div class="zhinanzheng"
         @click="handleClickZhinan">
      <img src="@/assets/login/zhinanzheng.svg"
           alt="">
    </div>
    <div class="left-select">
      <div>
        <el-select v-model="area"
                   placeholder="请选择">
          <el-option v-for="item in areaList"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div>
        <el-select v-model="jiedao"
                   placeholder="请选择"
                   clearable=""
                   @change="jiedaoChange">
          <el-option v-for="item in jiedaoList"
                     :key="item.code"
                     :label="item.regionName"
                     :value="item.code">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="right-select">
      <div id="parkBut"
           class="right-item"
           :style="showPark? colorJson[theme].select: colorJson[theme].normal"
           @click="parkClick">
        <el-image :src="colorJson[theme].park"></el-image>
        <span>停车场</span>
      </div>
      <div id="personBut"
           class="right-item"
           :style="showPerson? colorJson[theme].select: colorJson[theme].normal"
           @click="personClick">
        <el-image :src="colorJson[theme].renyuan"></el-image>
        <span>人员</span>
      </div>
      <el-popover placement="bottom"
                  trigger="click">
        <div class="right-pop">
          <el-tree :data="rightTreeData"
                   show-checkbox
                   @check-change="handleCheckChange"
                   :style="colorJson[theme].select">
            <div class="tree-item"
                 slot-scope="{ node, data }">
              <el-image style="width: 13px; height: 13px; margin-right: 5px"
                        :src="theme==='dark'? data.img :data.img1"></el-image>
              <span>{{ node.label }}</span>
            </div>
          </el-tree>
        </div>
        <div class="right-item"
             id="equipmentBut"
             :style="colorJson[theme].normal"
             slot="reference">
          <el-image :src="colorJson[theme].dev"></el-image>
          <span>设备</span>
        </div>
      </el-popover>
    </div>

    <el-dialog :title="popTitle"
               :visible.sync="showParkDetail"
               append-to-body
               :modal="false"
               destroy-on-close
               width="1400px"
               style="margin-left: 10%">
      <div class="parkDetails-box">
        <parkDetails v-if="showParkDetail"
                     :data="data"></parkDetails>
        <div class="parkDetails-watch">
          <div class="parkDetails-watch-title"
               :style="colorJson[theme].gaowei">高位监控</div>
          <div class="parkDetails-watch-content">
            <div class="parkDetails-watch-content-lineone">
              <div :style="colorJson[theme].gaowei">选择监控</div>
              <div class="parkDetails-watch-content-select">
                <el-select v-model="selectVideo"
                           placeholder="请选择"
                           style="width: 200px">
                  <el-option v-for="item in videoList"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
                <div @click="getVideo">实时播放</div>
              </div>
            </div>
            <video controls
                   muted
                   id="videoElementOne"></video>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :title="popTitle"
               :visible.sync="showMonitorDetail"
               :modal="false"
               append-to-body
               destroy-on-close>
      <monitorDetails :data="data"
                      @cbShow="showDetails"></monitorDetails>
    </el-dialog>
    <!-- 人员信息弹窗 -->
    <el-dialog title="人员详情"
               :modal="false"
               :visible.sync="showPersonDetail"
               append-to-body
               destroy-on-close>
      <el-form label-width="150px"
               :model="personFormList"
               :inline="true"
               size="small"
               label-position="right">
        <el-row justify="space-around"
                style="padding-left:40px;margin-top:20px">
          <el-col :span="12">
            <el-form-item label="巡检员编号">
              <el-input v-model="personFormList.userCode"
                        readonly
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="巡检员名称">
              <el-input v-model="personFormList.userName"
                        readonly
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around"
                style="padding-left:40px">
          <el-col :span="12">
            <el-form-item label="性别">
              <el-input v-model="personFormList.userSex"
                        readonly
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年龄">
              <el-input v-model="personFormList.userAge"
                        readonly
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around"
                style="padding-left:40px">
          <el-col :span="12">
            <el-form-item label="联系电话">
              <el-input v-model="personFormList.phoneNumber"
                        readonly
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属街道">
              <el-input v-model="personFormList.departName"
                        readonly
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around"
                style="padding-left:40px">
          <el-col :span="12">
            <el-form-item label="所属巡检组">
              <el-input v-model="personFormList.inspectionGroupName"
                        readonly
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="最后点位时间">
              <el-input v-model="personFormList.reportTime"
                        readonly
                        class="dt-form-width"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row justify="space-around"
                style="padding-left:40px">
          <el-col :span="15">
            <el-form-item label="轨迹回放">
              <el-date-picker v-model="replayTime"
                              type="datetimerange"
                              align="right"
                              size="medium"
                              unlink-panels
                              value-format="yyyy-MM-dd HH:mm:ss"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span='9'>
            <el-button type="primary"
                       @click="handleClickPlay(personFormList)">播放</el-button>
          </el-col>
        </el-row>
      </el-form>
      <div class="map-containerIn">
        <div id="mapIn"></div>
      </div>
    </el-dialog>
    <div v-show="false">
      <!-- 停车场信息窗口 -->
      <parkInfoWindow ref="parkingInfoWindow"
                      :id="id"
                      :data="data"
                      @close="infoWindowClose"
                      @detailsClick="detailsClick"
                      :style="colorJson[theme].select"></parkInfoWindow>
      <!-- 巡检员信息窗口 -->
      <personInfoWindow ref="personInfoWindow"
                        :id="id"
                        :data="data"
                        @close="infoWindowClose"
                        @butClick="playback"></personInfoWindow>
      <!-- 高位视频信息窗口 -->
      <monitorInfoWindow ref="monitorInfoWindow"
                         :id="id"
                         :data="data"
                         :status="data.status"
                         @close="infoWindowClose"
                         @detailsClick="monitorDetailsClick"></monitorInfoWindow>
      <!-- 地锁信息窗口 -->
      <landLock ref="landLock"
                :id="id"
                :data="data"
                :status="data.status"
                @close="infoWindowClose"
                @detailsClick="detailsClick"></landLock>
    </div>
    <div style="position: absolute; top: 50%; left: 50%"></div>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { mapState } from 'vuex'
import Amap from "@/common/js/map.js";
import flvjs from "flv.js";
import parkInfoWindow from "./components/parkInfoWindow";
import parkDetails from "./components/parkDetails";
import personInfoWindow from "./components/personInfoWindow";
import monitorInfoWindow from "./components/monitorInfoWindow";
import monitorDetails from "./components/monitorDetails";
import landLock from "./components/landLock";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    parkInfoWindow,
    parkDetails,
    personInfoWindow,
    monitorInfoWindow,
    monitorDetails,
    landLock,
  },
  data () {
    //这里存放数据
    return {
      colorJson: {
        dark: {
          select: 'background:#137fa1;border: none',
          normal: 'background:#143c6d;border: none',
          gaowei: "",
          park: require('@/assets/dataBoards/park.png'),
          renyuan: require('@/assets/dataBoards/renyuanguanli.png'),
          dev: require('@/assets/dataBoards/charge.png')
        },
        light: {
          select: 'background:#F5F7FA;border:none',
          normal: 'background:#fff;border: none',
          gaowei: "color:black",
          park: require('@/assets/parkIcon/park.png'),
          renyuan: require('@/assets/parkIcon/gasStation.png'),
          dev: require('@/assets/parkIcon/charge.png')
        }
      },

      map: null, // 地图实例

      area: "渝北区",
      areaList: [
        {
          value: "渝北区",
          label: "渝北区",
        },
      ],

      jiedao: "",
      jiedaoList: [],
      // 右侧弹出框树形组件数据
      rightTreeData: [
        {
          label: "高位视频",
          value: "monitor",
          img: require("@/assets/dataBoards/monitor.png"),
          img1: require("@/assets/parkIcon/cameraLight.png"),
        },
        {
          label: "地锁",
          value: "landLock",
          img: require("@/assets/dataBoards/landLock.png"),
          img1: require("@/assets/parkIcon/floorLockLight.png"),
        },
        {
          label: "诱导屏",
          value: "screen",
          img: require("@/assets/onlineMonitor/guideScreen.png"),
          img1: require("@/assets/parkIcon/screenLight.png"),
          children: [
            {
              label: "一级诱导屏",
              value: "screen",
              img: require("@/assets/onlineMonitor/guideScreen.png"),
              img1: require("@/assets/parkIcon/screenLight.png"),
            },
            {
              label: "二级诱导屏",
              value: "screen",
              img: require("@/assets/onlineMonitor/guideScreen.png"),
              img1: require("@/assets/parkIcon/screenLight.png"),
            },
            {
              label: "三级诱导屏",
              value: "thirdScreen",
              img: require("@/assets/onlineMonitor/guideScreen.png"),
              img1: require("@/assets/parkIcon/screenLight.png"),
            },
          ],
        },
      ],
      parkList: [],// 停车场列表

      monitorList: [], // 高位视频列表
      landLockList: [],// 地锁列表
      screenList: [],
      firstScreenList: [],   // 一级诱导屏
      thirdScreenList: [], //三级诱导屏
      personList: [],   // 人员数据
      showPark: false,// 停车场是否选中
      showPerson: false,  // 人员是否选中
      showPersonDetail: false,// 是否展示人员详情弹窗
      personFormList: {},// 人员弹窗详情
      current: [],
      replayTime: null,// 人员轨迹播放时间
      showParkDetail: false, // 是否展示停车场详情弹窗
      showMonitorDetail: false, // 是否展示高位视频详情弹窗

      // 信息窗口绑定变量
      id: "",
      data: {},
      // 停车场详情弹窗标题
      popTitle: "",
      flvUrlOne: "",
      videoList: [],
      selectVideo: "",
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
    })
  },
  //监控data中的数据变化
  watch: {
    theme () {
      this.initMap();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.getRegion(); // 获取行政区域
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initMap(); // 初始化地图
    this.parkClick();    // 点击停车场
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 获取当天时间
    queryCurrentDay () {
      let day = new Date()
      let a = day.getFullYear()
      let b = day.getMonth() + 1
      let c = day.getDate()
      if (JSON.stringify(b).length === 1) {
        b = "0" + b
      }
      if ((JSON.stringify(c).length === 1)) {
        c = "0" + c
      }
      this.current[0] = a + "-" + b + "-" + c + " 00:00:00"
      this.current[1] = a + "-" + b + "-" + c + " 23:59:59"
      this.replayTime = this.current
    },
    // 初始化地图
    initMap () {
      this.map = new Amap(
        "map",
        [106.562865, 29.600376],
        // [118.9237774, 31.91082],
        {
          parking: { mapIco: require("@/assets/dataBoards/pointParking.png") },
          person: { mapIco: require("@/assets/dataBoards/pointPerson.png") },
          monitor: { mapIco: require("@/assets/dataBoards/monitorOnline.png") },
          landLock: { mapIco: require("@/assets/dataBoards/landLockPoint.png") },
          screen: { mapIco: require("@/assets/onlineMonitor/guideScreenOnline.png") },
        },
        // 信息窗口所需的dom
        {
          parking: this.$refs.parkingInfoWindow.$el,
          person: this.$refs.personInfoWindow.$el,
          monitor: this.$refs.monitorInfoWindow.$el,
          landLock: this.$refs.monitorInfoWindow.$el,
          screen: this.$refs.monitorInfoWindow.$el,
        }
      );
      // 停车场信息窗体内数字
      this.map.clickMark = (id, data) => {
        this.id = "";
        this.data = {};
        this.$dataBoards.getParkSpaceListByParkId({ parkId: data.parkId }).then(res => {
          data.inAll = res.resultEntity.length
          this.$dataBoards.getOrderOnlineNoPage({ parkId: data.parkId }).then(response => {
            data.minutes = data.inAll - response.resultEntity.length
            this.id = id;
            this.data = data;
          })
        }).catch(() => {
          data.minutes = "获取失败!";
          data.inAll = "";
          this.id = id;
          this.data = data;
        });
      };
    },
    // 点击停车场
    parkClick () {
      this.showPark = !this.showPark;
      if (this.showPark) {
        this.getYard(() => {
          this.map.setMarker("parking", this.parkList);
        });
      } else {
        this.map.removeMarker("parking", this.parkList);
        this.map.closeInfoWindow();
      }
    },
    // 获取停车场
    getYard (callback) {
      const loading = this.$loading({
        target: "#parkBut",
        lock: false,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
      let info = {
        parkTypeCode: "1",
        areaCode: this.jiedao,
        pageNum: 1,
        pageSize: 10000,
      };
      this.$yardManagementApi.ParkController(info).then((response) => {
        this.parkList = response.resultEntity.list;
        loading.close();
        if (callback) {
          callback();
        }
      }).catch(() => {
        loading.close();
      });
    },
    //获取停车场名称
    getQueryDict ({ params, cb }) {
      this.$queryDict.queryDictWithAuth(params).then((response) => {
        cb(response)
      })
    },
    // 获取视频 ⬇⬇⬇
    getVideo () {
      let parmas = {
        parkId: this.data.parkId,
        parkSpaceId: this.selectVideo,
      };
      this.$realTimeVideoMonitor.getVideoUrl(parmas).then((res) => {
        let url = res
        this.flvUrlOne = url
        if (flvjs.isSupported()) {
          var videoElementOne = document.getElementById("videoElementOne");
          var flvPlayer = flvjs.createPlayer({
            type: "flv",
            url: this.flvUrlOne,
          });
          flvPlayer.attachMediaElement(videoElementOne);
          flvPlayer.load();
          flvPlayer.play();
        }
      });
    },
    // 获取设备列表 ⬇⬇⬇
    getDevice () {
      let params = {
        pageNum: 1,
        pageSize: 10000,
        address: this.data.address,
      };
      this.$cameraDev.queryLotTopCameraList(params).then((res) => {
        let videoList = [];
        res.resultEntity.list.forEach((item) => {
          videoList.push({ ...item, value: item.deviceId, label: item.deviceName, });
        });
        this.videoList = videoList;
        this.selectVideo = videoList[0] ? videoList[0].value : "";
      });
    },
    // 获取高位视频
    getTopCameraList (callback) {
      const loading = this.$loading({
        target: "#equipmentBut",
        lock: false,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.6)",
      });
      let info = {
        pageNum: 1,
        pageSize: 10000,
      };
      this.$cameraDev.queryLotTopCameraList(info).then((res) => {
        res.resultEntity.list.forEach((item) => {
          item.deviceTypeName = "高位视频";
          item.type = "monitor";
          // item.status = "1";
        });
        this.monitorList = res.resultEntity.list;
        loading.close();
        if (callback) {
          callback();
        }
      }).catch(() => {
        loading.close();
      });
    },
    // 获取地锁
    getLandLockList (callback) {
      const loading = this.$loading({
        target: "#equipmentBut",
        lock: false,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.6)",
      });
      let info = {
        pageNum: 1,
        pageSize: 10000,
      };
      this.$floorLock.queryGroundLockList(info).then((res) => {
        res.resultEntity.list.forEach((item) => {
          item.deviceTypeName = "地锁";
          item.type = "landLock";
        });
        this.landLockList = res.resultEntity.list;
        loading.close();
        if (callback) {
          callback();
        }
      }).catch(() => {
        loading.close();
      });
    },
    // 获取诱导屏类型
    getGuidingScreenTypeList () {
      let info = {
        columnName: ["value", "name"],
        tableName: "tb_dict",
        whereStr: [
          {
            colName: "parent_id",
            value: "37E3D16AA06045859552A5B4AEF4429D",
          },
        ]
      };
      this.$queryDict.queryDict(info).then((response) => {
        response.resultEntity.forEach((item) => {
          item.label = item.name;
          item.value = item.code;
        });
        this.rightTreeData = response.resultEntity;
      });
    },
    // 获取诱导屏信息
    getFirstScreenList (callback) {
      const loading = this.$loading({
        lock: false,
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.6)",
      });
      let info = {
        pageNum: 1,
        pageSize: 10000,
      };
      this.$inductionScreen.queryGuidingScreenList(info).then((res) => {
        res.resultEntity.list.forEach((item) => {
          item.deviceTypeName = "诱导屏";
          item.type = "screen";
        });
        this.firstScreenList = res.resultEntity.list;
        this.thirdScreenList = res.resultEntity.list;
        loading.close();
        if (callback) {
          callback();
        }
      }).catch(() => {
        loading.close();
      });
    },
    // 获取街道
    getRegion () {
      this.$dataBoards.queryRegion({}).then((res) => {
        this.jiedaoList = res.resultEntity;
      });
    },
    // 街道发生改变
    jiedaoChange (val) {
      let info = {
        "columnName": ["park_id", "park_name"],
        "tableName": "tb_park",
        "whereStr": [
          {
            "colName": "park_type_code",
            "value": "1"
          },
          {
            "colName": "area_code",
            "value": val
          }
        ]
      }
      this.$queryDict.queryDictWithAuth(info).then(res => {
        if (res.resultEntity.length !== 0) {
          res.resultEntity.forEach(item => {
            this.queryParkDetails(item.code)
          })
        }
        else if (res.resultEntity.length === 0) {
          this.$message({ message: "该街道暂无停车场!", type: 'warning' });
        }
      })
    },
    // 查询停车场信息
    queryParkDetails (val) {
      let info = {
        parkTypeCode: "1",
        parkId: val,
        pageNum: 1,
        pageSize: 10
      }
      this.$yardManagementApi.ParkController(info).then(res => {
        res.resultEntity.list.forEach(item => {
          this.map.setCenter(item.longitude, item.latitude);
          this.map.setZoom(17);
        })
      })
    },
    // 点击人员
    personClick () {
      this.showPerson = !this.showPerson;
      if (this.showPerson) {
        this.$dataBoards.getRealtimeLocation({}).then(res => {
          console.log(res.resultEntity);
          this.personList = res.resultEntity
          this.map.setMarker("person", this.personList);
        })
      } else {
        this.map.removeMarker("person", this.personList);
        this.map.closeInfoWindow();
      }
    },
    // 设备选择
    handleCheckChange (node, a) {
      if (node.value === "monitor") {
        if (a) {
          this.getTopCameraList(() => {
            this.map.setMarker(node.value, this.monitorList);
          });
        } else {
          this.map.removeMarker(node.value, this.monitorList);
          this.map.closeInfoWindow();
        }
      } else if (node.value === "landLock") {
        if (a) {
          this.getLandLockList(() => {
            this.map.setMarker(node.value, this.landLockList);
          });
        } else {
          this.map.removeMarker(node.value, this.landLockList);
          this.map.closeInfoWindow();
        }
      } else if (node.value === "thirdScreen") {
        if (a) {
          this.getFirstScreenList(() => {
            this.map.setMarker("screen", this.thirdScreenList);
          });
        } else {
          this.map.removeMarker("screen", this.thirdScreenList);
          this.map.closeInfoWindow();
        }
      }
    },
    // 点击信息窗口详情按钮
    detailsClick (id, data) {
      if (id === "parking") {
        this.popTitle = data.parkName;
        this.id = id;
        this.data = data;
        this.showParkDetail = true;
        this.getQueryDict({
          params: {
            columnName: ["park_space_id", "park_space_detecter_id"],
            tableName: "tb_park_space",
            whereStr: [
              {
                colName: "park_id",
                value: this.data.parkId,
              },
            ],
          }, cb: (response => {
            let videoList = [];
            for (let i = 0; i < response.resultEntity.length; i++) {
              const item = response.resultEntity[i];
              if (item.name) {
                videoList.push({ ...item, value: item.code, label: item.code });
              }
            }
            this.videoList = videoList;
            this.selectVideo = videoList[0] ? videoList[0].value : "";
          })
        });
      }
    },
    // 点击信息窗口关闭按钮
    infoWindowClose () {
      this.map.closeInfoWindow();
    },
    // 人员信息窗口点击回放
    playback (data) {
      let info = {
        userCode: data,
        pageNum: 1,
        pageSize: 15
      }
      this.$systemUserManagement.systemManQuery(info).then(response => {
        let that = this
        let info1 = {
          pageNum: 1,
          pageSize: 15,
          userName: response.resultEntity.list[0].userName
        }
        that.$chargeManManagement.queryInspectionList(info1).then(res => {
          console.log(res.resultEntity, '??????');
          that.personFormList = res.resultEntity.list[0]
          let _that = that
          let info = {
            userCode: data
          }
          _that.$chargeManManagement.getRealtimeLocationByUserCode(info).then(result => {
            if (result.resultEntity) {
              _that.personFormList.reportTime = result.resultEntity.reportTime
              _that.$forceUpdate()
              _that.queryCurrentDay()
              _that.showPersonDetail = true
              _that.$nextTick(() => {
                _that.initMapIn()
              })
            }
          })
        })
      })
    },
    initMapIn () {
      this.mapIn = new Amap(
        "mapIn",
        [106.562865, 29.600376]
      )
    },
    // 获取人员轨迹
    handleClickPlay (val) {
      let info = {
        userCode: val.userCode,
        start: this.replayTime[0],
        end: this.replayTime[1]
      }
      this.$chargeManManagement.getTrajectory(info).then(res => {
        console.log(res.resultEntity[0], res.resultEntity[0].longitude, res.resultEntity[0].latitude);
        this.mapIn.setCenter(Number(res.resultEntity[0].longitude), Number(res.resultEntity[0].latitude))
        let a = []
        // res.resultEntity.splice(0, 1)
        res.resultEntity.forEach(item => {
          a.push([Number(item.longitude), Number(item.latitude)])
        })
        this.mapIn.setOnlyMarker(a)
      })
    },
    // 点击高位视频信息窗口详情按钮
    monitorDetailsClick (id, data) {
      this.id = id
      this.data = data;
      this.popTitle = `设备详情`;
      this.showMonitorDetail = true;
    },
    //设备详情展示
    showDetails (value) {
      this.showMonitorDetail = value;
    },
    // 点击指南针
    handleClickZhinan () {
      this.map.setCenter(106.562865, 29.600376);
    },
  },
};
</script>
<style lang='scss' scoped>
.body {
  position: relative;
  width: 100%;
  height: 100%;
}
.map-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  #map {
    width: 100%;
    height: 100%;
  }
}
.map-containerIn {
  width: 100%;
  height: 500px;
  #mapIn {
    width: 100%;
    height: 100%;
  }
}
.zhinanzheng {
  width: 80px;
  height: 80px;
  position: absolute;
  right: 60px;
  bottom: 60px;
}
.left-select {
  position: absolute;
  left: 20px;
  top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 20%;
  height: 40px;
  div {
    width: 98%;
  }
}
.right-select {
  position: absolute;
  right: 20px;
  top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 174px;
  .right-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
    width: 55px;
    height: 50px;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #0f81a2;
    font-size: 6px;
    line-height: 12px;
    overflow: hidden;
    .el-image {
      width: 20px;
      height: 20px;
    }
    &:active {
      background: #137fa1;
      border: 1px solid #11e1fa;
    }
    &:focus {
      background: #137fa1;
      border: 1px solid #11e1fa;
    }
  }
  .right-pop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 110%;
    height: 110%;
  }
  .tree-item {
    background: green;
  }
}
/deep/.el-loading-spinner {
  margin-top: -10px;
}
/deep/.el-dialog__body {
  padding: 0;
}
.parkDetails-box {
  display: flex;
  .parkDetails-watch {
    color: white;
    width: 550px;
    .parkDetails-watch-title {
      height: 46px;
      line-height: 46px;
      text-align: center;
      margin-top: 13px;
    }
    .parkDetails-watch-content {
      padding: 0px 10px;
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      height: calc(100% - 85px);
      & > div {
        width: 100%;
      }
      & > div > div {
        padding-bottom: 10px;
      }
      .parkDetails-watch-content-lineone {
      }
      .parkDetails-watch-content-select {
        display: flex;
        & > div:last-child {
          cursor: pointer;
          width: 100px;
          background: #2dbedd;
          height: 30px;
          border-radius: 4px;
          text-align: center;
          line-height: 30px;
          margin-left: 20px;
        }
      }
      #videoElementOne {
        margin-bottom: 20px;
        height: 300px;
        width: 100%;
      }
    }
  }
}
.dt-form-width {
  width: 230px;
}
</style>