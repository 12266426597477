<!--
 * @Description: 停车场详情弹窗组件
 * @Author: yanxiao
 * @Github: 
 * @Date: 2021-05-28 10:58:04
 * @LastEditors: zhoucheng
-->
<template>
  <div class="main">
    <div class="row1">
      <template v-for="item in titleList">
        <div :key="item.key">
          <div :style="colorJson[theme].color">{{ item.label }}</div>
          <div>{{ item.count }}</div>
        </div>
      </template>
    </div>
    <!-- 停车场 -->
    <div class="berth-image">
      <div class="berth-row1">
        <div class="card-container"
             v-for="(item,idx) in topList"
             :key="idx">
          <!-- 在停 -->
          <div class="card-box">
            <div class="img-box">
              <div class="dt-car"
                   :style="item.plateNumber?'background:#e56965':'background:#34dca3'">
                <div v-if="item.plateNumber">
                  <div class="dt-plate-color">
                    {{ item.numberPlateColorName?item.numberPlateColorName:'' }}
                  </div>
                  <div class="dt-plate">{{ item.plateNumber }}</div>
                </div>
                <div v-else>
                  <span style="font-size:40px;color:#ffffff;font-weight:600">P</span>
                </div>
              </div>
            </div>
            <div class="card-title">车位编号</div>
            <div class="card-title">{{ item.parkSpaceNumber }}</div>
            <div class="card-title">入场时间</div>
            <div class="card-title">
              <span style="font-size: 12px">{{ item.entranceTime }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="berth-row2">{{parkName}}</div>
      <div class="berth-row3">
        <div class="card-container"
             v-for="(item, index) in bottomList"
             :key="index">
          <div class="card-box">
            <div class="card-title">车位编号</div>
            <div class="card-title">
              {{ item.parkSpaceNumber }}
            </div>
            <div class="card-title">入场时间</div>
            <div class="card-title">
              <span style="font-size: 12px">{{ item.entranceTime }}</span>
            </div>
            <div class="img-box">
              <div class="dt-car"
                   :style="item.plateNumber?'background:#e56965':'background:#34dca3'">
                <div v-if="item.plateNumber">
                  <div class="dt-plate-color">
                    {{ item.numberPlateColorName }}
                  </div>
                  <div class="dt-plate">
                    {{ item.plateNumber }}
                  </div>
                </div>
                <div v-else>
                  <span style="font-size:40px;color:#ffffff;font-weight:600">P</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div>
      <span class="total">共 {{ total }} 个车位</span>
      <el-pagination class="pagination"
                     :page-size="pageSize"
                     :total="total"
                     layout="prev,pager,next"
                     :current-page="pageNum"
                     :background="true"
                     @current-change="getberth"></el-pagination>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路  径》';
import { mapState } from 'vuex'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    data: {
      type: Object,
    },
  },
  data () {
    //这里存放数据
    return {
      colorJson: {
        dark: {
          color: 'color:#ffffff'
        },
        light: {
          color: 'color:black',
        }
      },
      parkName: '',
      topList: [],
      bottomList: [],
      totalList: [],
      onlinelist: [],
      infoList: {}, //信息列表
      parkSpaceList: {}, //车位下拉框
      pageNum: 1,
      pageSize: 12,
      total: 0,
      titleList: [
        {
          label: "总车位数",
          key: "inAll",
          count: "0",
          color: "#e350d4",
        },
        {
          label: "剩余车位数",
          key: "minutes",
          count: "0",
          color: "#4bcde9",
        },
        {
          label: "今日停车车次",
          key: "parkTimes",
          count: "0",
          color: "#4ee458",
        },
        {
          label: "今日产生订单数",
          key: "parkTimes",
          count: "0",
          color: "#e5d93a",
        },
        {
          label: "已完成订单数",
          key: "orderCompleteNumber",
          count: "0",
          color: "#e68145",
        },
        {
          label: "已收金额（元）",
          key: "totalReceivableMoneyAmount",
          count: "0",
          color: "#bd454d",
        },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapState({
      theme: (state) => state.app.theme,
    })
  },
  //监控data中的数据变化
  watch: {
    data () {
      this.getMenu();
    },
    mapState () {
      this.init()
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

    this.parkName = this.data.parkName
    this.getOnlineStatus();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.init();
    this.getMenu();
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    init () {
      Promise.all([this.getMenu(), this.getIncome(this.data.parkId)]).then(res => {
        let params = {}
        for (let i = 0; i < res.length; i++) {
          const item = res[i];
          params = { ...params, ...item }
        }
        params = { ...params, ...this.data }
        this.titleList.forEach(item => {
          if (item.key == 'totalReceivableMoneyAmount') {
            item.count = params[item.key] / 100 || "0"
          } else {
            item.count = params[item.key] || "0"
          }
        })
      });
    },
    // 获取泊位信息
    getberth (val) {
      this.pageNum = val;
      this.getOnlineStatus();
    },
    //获取
    getMenu () {
      return new Promise((r) => {
        this.$onroadParkRoadNext.baseInfo({ parkId: this.data.parkId }).then((res) => {
          this.infoList = res.resultEntity;
          r(res.resultEntity);
        });
      });
    },
    getIncome (parkId) {
      return new Promise((r) => {
        this.$dataBoards.getIncome({ parkId }).then((res) => {
          if (this.infoList.totalReceivedMoneyAmount) {
            this.infoList.totalReceivedMoneyAmount = Number(Number(this.infoList.totalReceivedMoneyAmount) / 100).toFixed(2)
          }
          r(res.resultEntity)
        });
      });
    },
    //线上状态
    getOnlineStatus () {
      let info = {
        parkId: this.data.parkId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      this.$onroadParkRoadNext.queryListByPage(info).then(res => {
        this.totalList = res.resultEntity.list
        this.total = res.resultEntity.total
        let info1 = {
          parkId: this.data.parkId,
        }
        this.$onroadParkRoadNext.getOrderOnlineNoPage(info1).then(response => {
          this.onlinelist = response.resultEntity
          this.onlinelist.forEach(item => {
            item.parkSpaceId = item.parkSpaceNumber
          })
          for (let j = 0; j < this.onlinelist.length; j++) {
            for (let i = 0; i < this.totalList.length; i++) {
              if (this.totalList[i].parkSpaceId === this.onlinelist[j].parkSpaceNumber) {
                // this.totalList.splice(i, 1)
                this.totalList[i] = this.onlinelist[j]
              }
            }
          }
          // this.onlinelist = [...this.onlinelist, ...this.totalList]
          this.totalList = [...this.totalList]
          let topList = [],
            bottomList = []
          this.totalList.forEach((item, idx) => {
            let json = {
              entranceTime: item.entranceTime,
              numberPlateColorCode: item.numberPlateColorCode,
              parkSpaceNumber: item.parkSpaceId,
              plateNumber: item.plateNumber,
              numberPlateColorName: item.plateColor,
              parkingstate: 1,
            }
            if (idx < 6) {
              topList.push(json)
            } else if (idx < 12) {
              bottomList.push(json);
            }
          });
          if (bottomList.length < 6) {
            for (let i = bottomList.length; i < 6; i++) {
              bottomList.push({});
            }
          }
          if (topList.length < 6) {
            for (let i = topList.length; i < 6; i++) {
              topList.push({});
            }
          }
          this.topList = topList;
          this.bottomList = bottomList;
        })
      })
    },
  },
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .row1 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 26px 0;
  }
  .row1 > div {
    width: calc((100% - 12px) / 6);
    text-align: center;
    border-right: 2px solid #053760;
    & > div:last-child {
      color: #00ebfb;
      font-size: 26px;
    }
  }
  .berth-image {
    background: rgb(128, 110, 110);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 550px;
    margin: 16px 10px;
    padding: 10px 0;
    background: url('../../../../../assets/onroadParkRoadNext/roadbackground.png');
    background-size: 100% 100%;
    .berth-row1 {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 33%;
    }
    .berth-row2 {
      width: 100%;
      height: 34%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include font_color('themefontcolor');
      font-size: 40px;
      font-weight: 600;
    }
    .berth-row3 {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 33%;
    }
    .card-container {
      width: 16%;
      height: 100%;
      .card-box {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100%;
        .img-box {
          position: relative;
          width: 80%;
          height: 60%;
          background-image: url('../../../../../assets/onroadParkRoadNext/cardBorder.png');
          background-size: 100% 100%;
          .card-border {
            position: absolute;
            width: 100%;
            height: 100%;
            background: #34dca3;
          }
          .dt-car {
            position: absolute;
            left: 3.5%;
            top: 8.5%;
            width: 93%;
            height: 83%;
            background: #e56965;
            display: flex;
            justify-content: center;
            align-items: center;
            .dt-plate-color {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #ffffff;
              line-height: 46px;
              text-align: center;
            }
            .dt-plate {
              font-size: 20px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #ffffff;
              line-height: 10px;
              text-align: center;
            }
          }
          .card {
            position: absolute;
            left: 38%;
            top: 16%;
            width: 23%;
            height: 68%;
          }
          .reversal {
            transform: rotateZ(180deg);
          }
        }
        .card-title {
          width: 80%;
          text-align: left;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #70aee8;
          line-height: 20px;
        }
      }
    }
  }
  .total {
    margin-left: 10px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #70aee8;
    line-height: 30px;
  }
  .pagination {
    float: right;
    margin: 0px 10px;
  }
}
</style>